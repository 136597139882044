.section-service {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
/* 
    width: 588px;
    height: 730.62px; */


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}


.section-title {
    /* Publication */

    /* width: 105px;
    height: 26px; */
    
    /* font-family: 'Lora';
    font-style: normal;
    font-weight: 400; */
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
    
    
    color: #333333;
    
    
    /* Inside auto layout */
    
    
    order: 0;
    flex-grow: 0;
}

.service-all {

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
/* 
    width: 588px;
    height: 114px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.item {

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    /* width: 588px;
    height: 22px; */


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.category {

    /* width: 67px;
    height: 22px; */

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.01em;

    color: #30C08D;


    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.sub-category {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    letter-spacing: -0.01em;

    color: #595959;
}

.content {

    /* width: 511px;
    height: 22px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    letter-spacing: -0.01em;

    color: #333333;


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 1;
}

