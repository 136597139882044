.name {

    /* font-family: 'Product Sans';
    font-style: normal; */
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: #333333;


    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.intro {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    letter-spacing: -0.01em;

    color: #333333;


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.external-link {
/* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    height: 40px;


    /* Inside auto layout */

    
    order: 2;
    flex-grow: 0;
}

.link {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 10px;

    width: 30px;
    height: 30px;

    background: linear-gradient(0deg, #F3F9F5, #F3F9F5), #18A0FB;
    border-radius: 4px;

    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.link-content {
    width: 20px;
    height: 20px;    
    /* Inside auto layout */
    
    
    order: 0;
    flex-grow: 0;
}

.link-icon {
    font-size: 20px;
    color: black;
}