.section-pub {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    /* width: 588px;
    height: 730.62px; */


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}


.section-title {
    /* Publication */

    /* width: 105px;
    height: 26px; */
    
    /* font-family: 'Lora';
    font-style: normal;
    font-weight: 400; */
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */
    
    
    color: #333333;
    
    
    /* Inside auto layout */
    
    
    order: 0;
    flex-grow: 0;
}

.subsection {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    /* width: 588px; */
    /* height: 496.62px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.subsection-title {
    /* Conference Papers */

    /* width: 588px;
    height: 22px; */

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.01em;

    color: #30C08D;


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.paper {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    /* width: 588px;
    height: 128.62px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.thumbnail {
    /* Rectangle 1 */

    width: 208px;
    height: 128.62px;

    /* background: #F3F9F5; */
    border-radius: 8px;

    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
    flex-shrink: 0;
}

.img {
    max-width:100%;
    max-height:100%;
    align-items: center;
    justify-content: center;
}

.paper-content {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    /* width: 360px;
    height: 124px; */


    /* Inside auto layout */

    flex-grow: 1;
}

.title {
    /* width: 360px; */
    /* height: 75px; */
    
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    
    letter-spacing: -0.01em;
    
    color: #333333;
    
    
    /* Inside auto layout */
    
    
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
}

.info {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;

    /* width: 360px;
    height: 41px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.tag {
    color: rgb(68, 156, 112);
    font-size: 14px;
    font-weight: 700;

}

.author {
    /* width: 360px; */
    /* height: 20px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    letter-spacing: -0.01em;

    color: #333333;


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.conf-link {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 2px;

    /* width: 227px;
    height: 19px; */


    /* Inside auto layout */

    
    order: 1;
    flex-grow: 0;
}

.conf {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 10px;

    /* width: 66px; */
    /* height: 19px; */

    background: #F3F9F5;
    border-radius: 38px;

    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.conf-content {
    /* width: 46px; */
    /* height: 15px; */
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */
    
    letter-spacing: -0.01em;
    
    color: #2B6531;
    
    
    /* Inside auto layout */
    
    
    order: 0;
    flex-grow: 0;
}

.paper-link {
    /* width: 159px;
    height: 15px; */
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */
    
    letter-spacing: -0.01em;
    text-decoration-line: underline;
    
    color: #333333;
    
    opacity: 0.8;
    margin-right: 10px;
    /* Inside auto layout */
    
    
    order: 1;
    flex-grow: 0;
}

.poster{
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    /* width: 588px; */
    /* height: 99px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.poster-content{

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    /* width: 588px; */
    /* height: 99px; */


    /* Inside auto layout */

    
    order: 0;
    flex-grow: 1;
}

.poster-title {

    /* width: 588px; */
    /* height: 50px; */

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    letter-spacing: -0.01em;

    color: #333333;


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.poster-info {

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;

    /* width: 588px; */
    /* height: 41px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.poster-author {

    /* width: 588px; */
    /* height: 20px; */
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    
    letter-spacing: -0.01em;
    
    color: #333333;
    
    
    /* Inside auto layout */
    
    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.poster-conf-link{

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 2px;

    /* width: 227px; */
    /* height: 19px; */


    /* Inside auto layout */

    
    order: 1;
    flex-grow: 0;
}


.poster-conf{
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 10px;

    /* width: 66px; */
    /* height: 19px; */

    background: #F3F9F5;
    border-radius: 38px;

    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.poster-conf-content {
    /* CHI 2021 */

    /* width: 46px; */
    /* height: 15px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    letter-spacing: -0.01em;

    color: #2B6531;


    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.poster-link {

    /* width: 159px; */
    /* height: 15px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    /* or 15px */

    letter-spacing: -0.01em;
    text-decoration-line: underline;

    color: #333333;

    opacity: 0.8;

    /* Inside auto layout */

    
    order: 1;
    flex-grow: 0;
}

.each-link {
    margin-right: 7px;
    color: #333333;
    opacity: 0.8;
}



mark {
    background-color: rgba(255, 198, 207, .7);
    color:rgb(51, 51, 51);
    margin: 1px;
    /* color: white; */
}