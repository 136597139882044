.section-edu {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    /* width: 588px;
    height: 164px; */

    /* Inside auto layout */

    
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.section-title {
/* 
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400; */
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */


    color: #333333;


    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.edu-all {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    /* width: 588px;
    height: 114px; */


    /* Inside auto layout */

    
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.edu {

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

    /* width: 588px;
    height: 22px; */


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.edu-content {

/* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
/* 
    width: 588px;
    height: 22px; */


    /* Inside auto layout */

    
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.level {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: -0.01em;

    color: #30C08D;


    /* Inside auto layout */

    
    order: 0;
    flex-grow: 0;
}

.school, .advisor {

    /* width: 223px;
    height: 20px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    letter-spacing: -0.01em;

    color: #333333;


    /* Inside auto layout */

    
    order: 1;
    flex-grow: 0;
}